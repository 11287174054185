import {Currency} from '@wix/ambassador-currency-converter/http';
import {SetCurrenciesRequest, SiteCurrency} from '@wix/ambassador-currency-site-settings/http';
import {WixStoresHttp} from '@wix/wixstores-client-core/dist/src/http/http';

export class CurrencyService {
  constructor(
    private readonly httpClient: WixStoresHttp,
    private readonly resolvePath: (path: string) => string = (path) => path
  ) {}

  public async getAvailableCurrenciesList(): Promise<Currency[]> {
    return ((await this.httpClient.get(this.resolvePath(`/_api/currency-converter/v1/currencies`))) as any).currencies;
  }

  public async getCurrencies(): Promise<SiteCurrency[]> {
    return (
      (await this.httpClient.get(this.resolvePath(`/_api/currency-converter-settings/v1/currencies/site`))) as any
    ).currencies;
  }

  public async setCurrencies(currencies: SetCurrenciesRequest): Promise<void> {
    await this.httpClient.put(this.resolvePath(`/_api/currency-converter-settings/v1/currencies/site`), currencies);
  }
}
